import React from "react"

export const DownArrow = ({
  color = "currentColor",
  ...props
}: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="26"
    viewBox="0 0 24 26"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m23.244 7.504-9.388 9.17V.01h-4.05v16.664L.418 7.504v6.145L11.83 24.82l11.414-11.172z"
      fill={color}
      fillRule="evenodd"
    />
  </svg>
)
